import { LinkType } from '~/db_types';
import { CommentType, ItemMasterType } from './db_types/swagger_types';
import { EntityType, getEnvironment, UserPermission } from './nasa_ui/types';
import { EsocApplication } from './nasa_ui/types/cosmic/paths';
import { CosmicSystem, CosmicUserSupportContext } from './nasa_ui/types/cosmic/system';
// Defining system config which is used for example by routing
export const COSMIC_SYSTEM = CosmicSystem.ESOC;
export const COSMIC_APPLICATION = EsocApplication.RISK;
export const COSMIC_ENVIRONMENT = getEnvironment();
// Every valid support context for this application
export var UserSupportContext;
(function (UserSupportContext) {
    UserSupportContext["EHP"] = "EHP";
    UserSupportContext["ESOC"] = "ESOC";
})(UserSupportContext || (UserSupportContext = {}));
// "Tracking" here means we are not the source of truth
const allTrackingDocumentTypes = [];
// Listing out, per UserSupportContext, which are valid DocumentTypes
export const UserSupportContextToValidDocumentTypes = new Map([
    [UserSupportContext.ESOC, []],
    [UserSupportContext.EHP, []]
]);
// Listing out, per UserSupportContext, which are valid ItemMasterTypes (equipment groups)
const allItemMasterTypes = [
    ItemMasterType.ANCILLARY_EQUIPMENT,
    ItemMasterType.BATTERY,
    ItemMasterType.CAPITAL_PROPERTY,
    ItemMasterType.COMMUNICATIONS_EQUIPMENT,
    ItemMasterType.CREW_EQUIPMENT_STOWAGE_PROV,
    ItemMasterType.CREW_ESCAPE_SYSTEM,
    ItemMasterType.CTSD,
    ItemMasterType.DECALS_PLACARDS_TAGS_PATCHES,
    ItemMasterType.DISPLAY_AND_CONTROL_EQUIPMENT,
    ItemMasterType.ELECTRICAL,
    ItemMasterType.EMU_LSS_FLIGHT_SSA_NBL,
    ItemMasterType.EVA_SUPPORT_EQUIPMENT,
    ItemMasterType.EVA_TOOLS,
    ItemMasterType.FACILITIES_CONTRACT,
    ItemMasterType.FILM,
    ItemMasterType.FOOD_AND_MEDICAL_SYSTEMS,
    ItemMasterType.GENERAL_COMPUTER_SUPPLIES,
    ItemMasterType.GROUND_SUPPORT_EQUIPMENT,
    ItemMasterType.IDWA,
    ItemMasterType.ISIL_IDWA,
    ItemMasterType.ISS_IDWA,
    ItemMasterType.ISS_M_AND_O_HARDWARE_PBA,
    ItemMasterType.ISS_WORK_THROUGH_FCE,
    ItemMasterType.MANNED_MANEUVERING_UNIT,
    ItemMasterType.MECHANICAL,
    ItemMasterType.MULTIPLE_EQ_GROUPS_FLIGHT,
    ItemMasterType.MULTIPLE_EQ_GROUPS_PSE,
    ItemMasterType.NON_FCE,
    ItemMasterType.OBS,
    ItemMasterType.OH_HOUSTON_OPS_JANITOR_SUPP,
    ItemMasterType.OH_HOUSTON_OPS_OFFICE_SUPP,
    ItemMasterType.OH_HOUSTON_OPS_REPROD_SUPP,
    ItemMasterType.OH_SR_QA_ONLY,
    ItemMasterType.OTHER_STS_SUPPORT_EQUIPMENT,
    ItemMasterType.PGSC_PCS,
    ItemMasterType.PHOTOGRAPHIC_EQUIPMENT,
    ItemMasterType.PREPACK_BENCH_REVIEW,
    ItemMasterType.SAFER,
    ItemMasterType.SHARED_CONSUMABLES,
    ItemMasterType.SPECIAL_ORDER,
    ItemMasterType.VEHICLE_ACCOMMODATIONS,
    ItemMasterType.VEHICLE_ACCOMMODATIONS,
    ItemMasterType.XEMU,
    ItemMasterType.XFSE,
    ItemMasterType.XINFO,
    ItemMasterType.XPGS,
    ItemMasterType.XPLSS
];
export const UserSupportContextToValidItemMasterTypes = new Map([
    [CosmicUserSupportContext.EHP, allItemMasterTypes],
    [CosmicUserSupportContext.ESOC, allItemMasterTypes]
]);
// Listing out, per UserSupportContext, which are valid EntityTypes
export const UserSupportContextToValidEntityTypes = new Map([
    [
        CosmicUserSupportContext.ESOC,
        [EntityType.DOCUMENT, EntityType.ORGANIZATION, EntityType.RISK, EntityType.USER_GROUP, EntityType.USER]
    ],
    [
        CosmicUserSupportContext.EHP,
        [EntityType.DOCUMENT, EntityType.ORGANIZATION, EntityType.RISK, EntityType.USER_GROUP, EntityType.USER]
    ]
]);
// Listing out, per UserSupportContext, which are valid LinkTypes
export const UserSupportContextToValidLinkTypes = new Map([
    [CosmicUserSupportContext.ESOC, [LinkType.OTHER]],
    [CosmicUserSupportContext.EHP, [LinkType.OTHER]]
]);
// Listing out, per UserSupportContext, which are valid CommentTypes
export const UserSupportContextToValidCommentTypes = new Map([
    [CosmicUserSupportContext.ESOC, [CommentType.GENERAL, CommentType.NOTE, CommentType.ORB_NOTE]],
    [CosmicUserSupportContext.EHP, [CommentType.GENERAL, CommentType.NOTE, CommentType.ORB_NOTE]]
]);
// per UserSupportContext, valid UserPermissions
const RISK_PERMISSIONS = [
    UserPermission.RISK_USER,
    UserPermission.RISK_ADMIN,
    UserPermission.ORGANIZATION_ADMIN,
    UserPermission.USER_ADMIN,
    UserPermission.USER_GROUPS_ADMIN,
    UserPermission.USER_PERMISSIONS
];
export const UserSupportContextToValidPermissions = new Map([
    [CosmicUserSupportContext.ESOC, RISK_PERMISSIONS],
    [CosmicUserSupportContext.EHP, RISK_PERMISSIONS]
]);
// export installation method for Vue so its on the Vue prototype
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $cosmicEnvironment: { value: COSMIC_ENVIRONMENT },
            $cosmicSystem: { value: COSMIC_SYSTEM },
            $validUserSupportContexts: { value: Object.values(UserSupportContext) }
        });
    }
};
