var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "hide-title-section": "",
      "modal-width": "80vw",
      "color": `--v-${_vm.EntityType.RISK}-base`
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [!_vm.selectedEntity ? _c('AppLoader', {
          attrs: {
            "color": _vm.EntityType.RISK,
            "type": "linear"
          }
        }) : _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs8": "",
            "mb-2": ""
          }
        }, [_c('AppText', {
          attrs: {
            "label": "Title",
            "required": ""
          },
          model: {
            value: _vm.formData.title,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "title", $$v);
            },
            expression: "formData.title"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('div', {
          staticClass: "mb-2"
        }, [_c('AppPageDescription', {
          attrs: {
            "remove-margin-bottom": ""
          }
        }, [_vm._v("Let COSMIC manage the risk number for you?")]), _c('AppBoolean', {
          attrs: {
            "falseText": "No, I want to enter my own number.",
            "hide-unknown": "",
            "horizontal": ""
          },
          model: {
            value: _vm.isCosmicManagedNumber,
            callback: function ($$v) {
              _vm.isCosmicManagedNumber = $$v;
            },
            expression: "isCosmicManagedNumber"
          }
        })], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.isCosmicManagedNumber ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "my-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppText', {
          attrs: {
            "label": "Number override",
            "required": ""
          },
          model: {
            value: _vm.formData.number,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "number", $$v);
            },
            expression: "formData.number"
          }
        })], 1)], 1) : _vm._e()], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Cause",
            "required": ""
          },
          model: {
            value: _vm.formData.cause,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "cause", $$v);
            },
            expression: "formData.cause"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Effect",
            "required": ""
          },
          model: {
            value: _vm.formData.effect,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "effect", $$v);
            },
            expression: "formData.effect"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-2": ""
          }
        }, [_c('AppFormWrapper', {
          attrs: {
            "required": !_vm.formData.organizationCode,
            "color": _vm.EntityType.ORGANIZATION,
            "size": "1"
          }
        }, [_c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.formData.organizationCode ? _c('SearchOrganization', {
          attrs: {
            "output-mode": _vm.OutputMode.string,
            "label": "Organization"
          },
          model: {
            value: _vm.formData.organizationCode,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "organizationCode", $$v);
            },
            expression: "formData.organizationCode"
          }
        }) : _vm._e()], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.formData.organizationCode ? _c('MiniOrganization', {
          attrs: {
            "code": _vm.formData.organizationCode,
            "read-only": false,
            "inline-clear": ""
          },
          on: {
            "inlineClear": function ($event) {
              _vm.formData.organizationCode = null;
            }
          }
        }) : _vm._e()], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "no-wrap": "",
            "align-center": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.RiskCategoryDisplay,
            "enum": _vm.RiskCategory,
            "label": "Risk category",
            "required": ""
          },
          model: {
            value: _vm.formData.category,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "category", $$v);
            },
            expression: "formData.category"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBoolean', {
          attrs: {
            "true-text": "EMU",
            "false-text": "Tools",
            "label": "Risk hardware type",
            "required": "",
            "hide-unknown": "",
            "horizontal": false
          },
          model: {
            value: _vm.riskHardwareType,
            callback: function ($$v) {
              _vm.riskHardwareType = $$v;
            },
            expression: "riskHardwareType"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Supplier?"
          },
          model: {
            value: _vm.formData.isSupplier,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "isSupplier", $$v);
            },
            expression: "formData.isSupplier"
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs8": "",
            "mb-2": ""
          }
        }, [_c('AppContact', {
          attrs: {
            "label": "Owner",
            "required": ""
          },
          model: {
            value: _vm.formData.ownerContactId,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "ownerContactId", $$v);
            },
            expression: "formData.ownerContactId"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs8": "",
            "mb-2": ""
          }
        }, [_c('AppContactMultiple', {
          attrs: {
            "label": "Stakeholders"
          },
          model: {
            value: _vm.stakeholders,
            callback: function ($$v) {
              _vm.stakeholders = $$v;
            },
            expression: "stakeholders"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-2": ""
          }
        }, [_c('v-layout', {
          staticClass: "risk-score-inputs",
          attrs: {
            "row": "",
            "no-wrap": "",
            "align-center": ""
          }
        }, [_c('v-flex', {
          staticClass: "pr-2",
          attrs: {
            "xs2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Initial likelihood",
            "min": 1,
            "max": 5,
            "required": ""
          },
          on: {
            "error": _vm.onLikelihoodError
          },
          model: {
            value: _vm.formData.likelihood,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "likelihood", $$v);
            },
            expression: "formData.likelihood"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Initial consequence",
            "min": 1,
            "max": 5,
            "required": ""
          },
          on: {
            "error": _vm.onConsequenceError
          },
          model: {
            value: _vm.formData.consequence,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "consequence", $$v);
            },
            expression: "formData.consequence"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": "",
            "mt-2": ""
          }
        }, [_c('AppBlockquote', {
          staticClass: "text-xs-center",
          attrs: {
            "title": "Initial Risk score"
          }
        }, [_c('span', {
          staticClass: "risk_score__score monospace_font",
          class: _vm.riskScoreClass
        }, [_vm._v(_vm._s(_vm.riskScore))])])], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-2": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs4": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.RiskReportabilityDisplay,
            "enum": _vm.RiskReportability,
            "label": "Reportability?"
          },
          model: {
            value: _vm.formData.showOnReports,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "showOnReports", $$v);
            },
            expression: "formData.showOnReports"
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "my-2": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": ""
          }
        }, [_c('v-flex', {
          class: {
            'mb-2': !_vm.formData.isClosed
          },
          attrs: {
            "xs2": "",
            "align-self-center": ""
          }
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Close Risk?"
          },
          model: {
            value: _vm.formData.isClosed,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "isClosed", $$v);
            },
            expression: "formData.isClosed"
          }
        })], 1), _c('v-slide-x-transition', [_c('v-flex', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.isClosed,
            expression: "formData.isClosed"
          }],
          attrs: {
            "xs3": "",
            "pr-2": ""
          }
        }, [_c('AppSelectEnum', {
          staticClass: "closure-type",
          attrs: {
            "chips": false,
            "display": _vm.RiskClosureTypeDisplay,
            "enum": _vm.RiskClosureType,
            "label": "Closure type",
            "required": ""
          },
          model: {
            value: _vm.formData.closureType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "closureType", $$v);
            },
            expression: "formData.closureType"
          }
        })], 1)], 1), _c('v-slide-y-transition', [_c('v-flex', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.formData.isClosed,
            expression: "formData.isClosed"
          }],
          attrs: {
            "xs3": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "label": "Close date override"
          },
          model: {
            value: _vm.formData.closedDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "closedDate", $$v);
            },
            expression: "formData.closedDate"
          }
        })], 1)], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Update status"
          },
          model: {
            value: _vm.formData.comment,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "comment", $$v);
            },
            expression: "formData.comment"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": !_vm.isFormValid || !_vm.riskHasChanges && !_vm.stakeholdersHaveChanged,
            "isSaving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEdit
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };